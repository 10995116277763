import React from "react";
import { useTranslation } from "react-i18next";
import AppDownload from "../../components/organisms/AppDownload";
import { isPlatform } from "@ionic/react";
import { useLocation } from "react-router-dom";

const classNames = require("classnames");
const { useState } = React;

const GuideBuyCU = () => {
    const { t, i18n } = useTranslation();
    const [ popupVisible, setPopupVisible ] = useState(false);
    const location = useLocation();

    const PopUp = () => {
        return (
            <>
            <div className={classNames({cu_popup: true, active: popupVisible})}>
                <div className="inner">
                    <button className="btn_close" onClick={(e)=>{setPopupVisible(false)}}>닫기</button>

                    <div className="scroll">
                        <h2>{t("buy_cu.buying_popup1")}</h2>
                        <p>{t("buy_cu.buying_popup2")}</p>
                        <div className="exp_box">
                            <p>
                                <b><em>편의점 직원분</em>에게 보여주세요!</b><br />
                                디스커버서울패스는<br /><em>프리페이드 책자</em>에서 구매할 수 있습니다.<br />
                                직원분은 아래 내용에 따라 진행해주세요.
                            </p>
                        </div>
                        <dl>
                            <dt>STEP 1</dt>
                            <dd>
                                <p>POS에서 <b>[서비스] 탭</b> 선택</p>
                                <img src={require("../../resources/img/cu_step01.jpg")} alt="디스커버서울패스 판매 가이드 이미지" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>STEP 2</dt>
                            <dd>
                                <p>[프리페이드/통신카드/국제전화] 선택</p>
                                <img src={require("../../resources/img/cu_step02.jpg")} alt="디스커버서울패스 판매 가이드 이미지" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>STEP 3</dt>
                            <dd>
                                <p>
                                    아래 고객님의 실제 <b>바코드를 스캔</b>해주세요. <br />
                                    (48시간/72시간/120시간권 중 택 1)
                                </p>
                                <img src={require("../../resources/img/cu_step03.png")} alt="디스커버서울패스 판매 가이드 이미지" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>STEP 4</dt>
                            <dd>
                                <p>현금 수취 후 <b>[확인]</b> 선택</p>
                                <img src={require("../../resources/img/cu_step04.jpg")} alt="디스커버서울패스 판매 가이드 이미지" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>STEP 5</dt>
                            <dd>
                                <p>
                                    영수증 출력 후, 고객님께 전달<br />
                                    <b>※영수증이 상품</b>입니다. <b>꼭 영수증을 손님께 건내주세요!</b>
                                </p>
                                <img src={require("../../resources/img/cu_step05.jpg")} alt="디스커버서울패스 판매 가이드 이미지" />
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>  
            </>
        )
    }
    
    return (
        <>
        <PopUp></PopUp>
        <div className="wrap_use cu">
            <p className="use_tit">{t("buy_cu.title")}</p>
            <div className="use_exp" dangerouslySetInnerHTML={{__html:t("buy_cu.desc")}}></div>
            
            <p className="use_tit">{t("buy_cu.buy_title")}</p>
            <div className="step cu">
                <dl className="step01 web">
                    <dt>STEP 1</dt>
                    <dd>
                    {t("buy_cu.step1")}<br /><b>{t("buy_cu.step11")}</b>
                    </dd>
                </dl>
                <dl className="step02">
                    <dt>STEP 2</dt>
                    <dd>
                    {t("buy_cu.step2")}<br />
                    {t("buy_cu.step22")}
                    </dd>
                </dl>
                <dl className="step03">
                    <dt>STEP 3</dt>
                    <dd>
                        {t("buy_cu.step3")} <b>{t("buy_cu.step33")}</b>
                    </dd>
                </dl>
            </div>
            
            
            {isPlatform("capacitor") ||  location.pathname.startsWith('/appnv')?null
            :
                <div className="exp_box use03">
                    <span>APP Download</span>
                    <AppDownload type="bold"></AppDownload>
                </div>
            }

            <div className="step cu02">
                <dl className="step04">
                    <dt>STEP 4</dt>
                    <dd>
                        {t("buy_cu.step4")}
                    </dd>
                </dl>
                <dl className="step05">
                    <dt>STEP 5</dt>
                    <dd>
                        {t("buy_cu.step5")}
                    </dd>
                </dl>
                <dl className="step06">
                    <dt>STEP 6</dt>
                    <dd>
                        {t("buy_cu.step6")}<br />
						{t("buy_cu.step66")}
                    </dd>
                </dl>
                <dl className="step07">
                    <dt>STEP 7</dt>
                    <dd>
                        {t("buy_cu.step7")} <br />
						{t("buy_cu.step77")}
                    </dd>
                </dl>
            </div>           

            <p className="use_tit">{t("buy_cu.buying_tip")}</p> 
            <div className="use_exp">
                <p dangerouslySetInnerHTML={{__html:t("buy_cu.buying_tipd")}}></p>
                <span dangerouslySetInnerHTML={{__html:t("buy_cu.buying_tipp")}} onClick={(e)=>{setPopupVisible(true)}}></span>
            </div>
            

            <p className="use_tit">{t("buy_cu.guide_title")}</p>
            <div className="exp_box">
                <p>
                    <b>{t("buy_cu.guide_d1")}</b>
                        {t("buy_cu.guide_d2")}
                </p>
                <p>
                    <b>{t("buy_cu.guide_d3")}</b>
					{t("buy_cu.guide_d4")}
				</p>
				<p>{t("buy_cu.guide_d5")}</p>
            </div>

        </div>
        </>
    );
};

export default GuideBuyCU;